@import "../../node_modules/bootstrap/scss/functions";

@import "bootstrap-variables";

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/bootstrap/scss/progress";

.resources-loader-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    .resources-loader-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

        .resources-loader-indicator {
          width: 80%;
          color: theme-color();
        }

        .resources-loader-progress {
            height: 1px;
            transition: height 0.6s ease;

            .progress-bar {
                transition: width 0.2s ease;
            }
        }

    }

    &.error .resources-loader-progress{
        height: 50px;
    }
}
